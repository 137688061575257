
import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { useWeb3Context } from 'web3-react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { BigNumber, utils } from 'ethers'
import RoundInput from '../../../components/RoundInput';
import Loading from '../../../components/Loading';

import useSaleContract from '../../../hooks/useSaleContract';
import { channelToNames } from '../../../config';
import useRequest from '@umijs/use-request';
import { useEvent } from '../../../hooks/utils';

export default function PortalConnected() {
  const context = useWeb3Context();

  return (
    <Card classes={{
      root: 'box'
    }}>
      <CardContent>
        <Typography color="textPrimary" variant="h4" gutterBottom>
          <Badge badgeContent={channelToNames[context.networkId]} color="error">
            DNFT Distribution Portal
          </Badge>
        </Typography>
  
        <Typography color="textSecondary" gutterBottom>
          {context.account}
        </Typography>
        <Button style={{ textTransform: 'unset' }} color="secondary" size="small" onClick={() => {
          context.unsetConnector();
        }}>Disconnect</Button>
        <Divider light />
        <br />

        {channelToNames[context.networkId] ? (
          <VestingClaimContent />
        ) : (
          <Alert severity="warning">Network unsupported!</Alert>
        )}
      </CardContent>
    </Card>
  );
}

function VestingClaimContent() {
  const context = useWeb3Context();

  const token = 'DNFT';

  const [round, setRound] = useState('STRATEGY');
  const saleContract = useSaleContract(round);

  const handleChange = useCallback(c => setRound(c), []);
  
  const vestingAsync = useRequest(async () => {
    const currentTime = parseInt(Date.now() / 1000); // 1624895612 + 30 * 86400 * 3 + 6 * 60 * 60 * 2 + 86400 * 480;

    const [ total, claimed, unlockable, startClaimTime ] =  await Promise.all([
      saleContract.balanceOf(context.account),
      saleContract.unlockBalanceOf(context.account),
      saleContract.unlockable(context.account, currentTime), // parseInt(Date.now() / 1000)), 
      // Math.trunc(Date.now() / 1000)),
      saleContract.startClaimTime(),
    ]);

    let claimable;
    if (startClaimTime.eq(currentTime) || unlockable.eq(0)) {
      claimable = BigNumber.from(0);
    } else {
      claimable = BigNumber.from(unlockable).sub(
        BigNumber.from(claimed)
      );
    }

    return { 
      total: utils.formatEther(BigNumber.from(total)), 
      claimed: utils.formatEther(BigNumber.from(claimed)),
      claimable: Number(utils.formatEther(claimable)).toFixed(6),
      canClaim: claimable.gt(0) && startClaimTime.gt(0),
    };
  }, {
    refreshDeps: [round, saleContract.address, context.account],
    initialData: {
      total: 0,
      claimed: 0,
      claimable: 0,
      canClaim: false,
    }
  });

  const claimAsync = useRequest(async () => {
    await saleContract.claims();
  },{
    manual: true,
    refreshDeps: [round, context.account, saleContract.address],
  });

  const handleClaim = useCallback(() => {
    claimAsync.run();
  }, [vestingAsync]);

  useEvent(saleContract, 'OnClaim', (investor, claim) => {
    if (investor === context.account) {
      vestingAsync.refresh();
    }
  });

  const vesting = vestingAsync.data;

  return (
    <>
      <p>Select your Vesting Round</p>
      <RoundInput value={round} onChange={handleChange} />

      {vestingAsync.loading && vestingAsync.data ? (
        <Loading />
      ) : (
        <>
          <p><strong>Available:</strong> {vesting.total} {token}</p>
          <p><strong>Claimed:</strong> {vesting.claimed} {token}</p>
          <p><strong>Claimable:</strong> {vesting.claimable} {token}</p>
        </>
      )}

      <br />

      <Button variant="contained" disabled={vestingAsync.loading || claimAsync.loading || vesting.canClaim === false} color="secondary" fullWidth onClick={handleClaim}>{
        claimAsync.loading ? 'Loading...' : 'Claim'
      }</Button>
      
      <Snackbar open={claimAsync.error} autoHideDuration={6000}>
        <Alert severity="success">
          {claimAsync.error && claimAsync.error.data.message}
        </Alert>
      </Snackbar>
    </>
  );
}