

import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useWeb3Context } from 'web3-react';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SelectWalletCard({ title, description }) {

  const classes = useStyles();

  const [selectWallet, setSelectWallet] = useState(false);
  const handleClose = useCallback(() => setSelectWallet(false), []);
  const handleOpenSelectWallet = useCallback(() => setSelectWallet(true), []);

  const context = useWeb3Context();

  return (
    <Card classes={{
      root: 'box'
    }}>
      <CardContent>
        <Typography color="textPrimary" variant="h4" gutterBottom>
          {title || 'DNFT Distribution Portal'}
        </Typography>

        {description !== false && (
          <Typography color="textSecondary" variant="h5" gutterBottom>
            {description || 'Claim Your Vesting'}
          </Typography>
        )}

        <Divider light />
        <br />
        <Typography color="outlined" gutterBottom>
          Let us detect if you have any unclaimed DNFT in seed or private round by connecting to your wallet first.
        </Typography>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={selectWallet}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={selectWallet}>
            <Card classes={{
              root: 'box'
            }}>
              <CardContent>
                <Typography variant="h5">Import your Wallet</Typography>
                <br />
                <Button variant="outlined" color="secondary" onClick={() => {
                  context.setFirstValidConnector(['MetaMask']);
                  handleClose();
                }}>
                  <i class="metamask" /> &nbsp;&nbsp;MetaMask
                </Button>
              </CardContent>
            </Card>
          </Fade>
        </Modal>

        <br />
        <Button variant="contained" color="secondary" onClick={handleOpenSelectWallet} disableElevation>
          Connect
        </Button>

      </CardContent>
    </Card>
  );
}
