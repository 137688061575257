import './App.css'

import React, {Component, useEffect } from 'react';
import Web3Provider, { Connectors } from 'web3-react'

import Container from '@material-ui/core/Container';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Portal from './routes/Portal';

import { channelToNames } from './config';

import theme from './theme';

const { InjectedConnector, NetworkOnlyConnector } = Connectors
const MetaMask = new InjectedConnector({ supportedNetworks: Object.keys(channelToNames).map(s => parseInt(s)) })

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm" classes={{
        root: 'app-container'
      }}>
        <Web3Provider
          connectors={{
            MetaMask
          }}
          libraryName="ethers.js"
        >
          <Router>
            <Switch>
              <Route exact path="/">
                <Portal />
              </Route>
            </Switch>
          </Router>
        </Web3Provider>
      </Container>
    </ThemeProvider>
  );
}