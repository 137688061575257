import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    padding: 20,
    textAlign: 'center'
  }
});

export default function Loading() {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <CircularProgress color="secondary" />
    </div>
  );
}