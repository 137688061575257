import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


export default function RoundInput({ value, onChange }) {

  return (
    <Select value={value} onChange={(e) => onChange(e.target.value)}>
          <MenuItem value="STRATEGY">STRATEGY</MenuItem>
          <MenuItem value="PRIVATE">PRIVATE</MenuItem>
        </Select>
  );
}