
import addresses from './addresses.json';

export const channelToNames = {
  // 1: "MainNet",
  4: "RinkeBy",
  56: "BSC",
  97: "BSC-testnet",
  1337: "HardHat",
  31337: "HardHat"
};

export const TOKEN = {
  1: {
    address: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
    abi: require('./erc20.abi.json'),
  },
  4: {
    address: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
    abi: require('./erc20.abi.json')
  },
  31337: {
    address: addresses[31337].TOKEN,
    abi: require('./erc20.abi.json')
  },
  97: {
    address: addresses[97].TOKEN,
    abi: require('./erc20.abi.json')
  }
};