

import React from 'react';
import { useWeb3Context } from 'web3-react';

import PortalConnected from './PortalConnected';
import SelectWalletCard from '../../components/SelectWalletCard';


export default function Portal() {
  const context = useWeb3Context();

  if (context.account) {
    return <PortalConnected />
  }

  return <SelectWalletCard />
}
