
import React, { useMemo, useEffect } from 'react';
import { useWeb3Context } from 'web3-react';
import { ethers } from 'ethers'

const caches = {};

const useContract = (addr, abi) => {
  const context = useWeb3Context();
  
  const contract = useMemo(() => {
    if (caches[`${addr}.${context.networkId}`]) {
      return caches[`${addr}.${context.networkId}`];
    }

    const contract = new ethers.Contract(addr, abi, context.library.getSigner());
    caches[`${addr}.${context.networkId}`] = contract;
    return contract;
  }, [addr, context.networkId]);

  return contract;
};

const useEvent = (contract, eventName, func) => {
  useEffect(() => {
    contract.on(eventName, func);
    return () => {
      contract.off(eventName, func);
    }
  }, [func, eventName, contract]);
}

export { useContract, useEvent };