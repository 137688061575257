import React, { useMemo } from 'react';
import { useWeb3Context } from 'web3-react';
import { ethers } from 'ethers'
import { abi } from './Sale.json';

import { useContract } from './utils';

import addresses from '../addresses.json';

const CONFIG = {
  31337: {
    STRATEGY: addresses[31337].STRATEGY,
    PRIVATE: addresses[31337].PRIVATE,
  },
  97: {
    STRATEGY: addresses[97].STRATEGY,
    PRIVATE: addresses[97].PRIVATE,
  },
  56: {
    STRATEGY: addresses[56].STRATEGY,
    PRIVATE: addresses[56].PRIVATE,
  }
}

export default function useSaleContract(round) {
  const context = useWeb3Context();
  return useContract(CONFIG[context.networkId][round], abi);
}