
import { createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },

  overrides: {
    MuiDivider: {
      light: {
        opacity: 0.5
      }
    },
    MuiButton: {
      contained: {
        borderRadius: 100,
        paddingLeft: 30,
        paddingRight: 30,
        fontWeight: 'bold'
      },
      containedSecondary: {
        color: '#fff'
      }
    },
    MuiSelect: {
      select: {
        paddingLeft: 25,
        paddingRight: '35px!important',
        textAlign: 'center'
      }
    }
  }
});

export default theme;
